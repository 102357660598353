import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { BadgeLabel, Space } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { ComponentLinks } from '../common';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Polymorphic = makeShortcode("Polymorphic");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "badgelabel"
    }}>{`BadgeLabel`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { BadgeLabel } from '@hse-design/react';
`}</code></pre>
    <ComponentLinks figma={'https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=580%3A4718'} storybook={'/?path=/story/badge-badgelabel--playground'} vue={'/components/HseBadge/HseBadgeLabel.html'} vueStorybook={'/?path=/story/badge-badgelabel--playground'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`BadgeLabel – компонент для отображения счетчика или статуса.`}</p>
    <p>{`Есть два варианта отображения: `}<inlineCode parentName="p">{`outlined`}</inlineCode>{` и `}<inlineCode parentName="p">{`filled`}</inlineCode>{`. `}<inlineCode parentName="p">{`outlined`}</inlineCode>{` с белым фоном и обводкой, `}<inlineCode parentName="p">{`filled`}</inlineCode>{` – полностью залит. Передайте в проп `}<inlineCode parentName="p">{`variant`}</inlineCode>{` нужный.`}</p>
    <p>{`У компонента 3 размера: `}<inlineCode parentName="p">{`smallX`}</inlineCode>{`, `}<inlineCode parentName="p">{`small`}</inlineCode>{`, `}<inlineCode parentName="p">{`medium`}</inlineCode>{`, размер задаётся пропом `}<inlineCode parentName="p">{`size`}</inlineCode>{`.`}</p>
    <p>{`По умолчанию BadgeLabel некликабельный.
Кликабельность задаётся пропом `}<inlineCode parentName="p">{`clickable`}</inlineCode>{`.
Не используйте проп `}<inlineCode parentName="p">{`clickable`}</inlineCode>{`, если компонент является составной частью другого кликабельного компонента.
Сделайте кликабельным весь внешний компонент.`}</p>
    <p>{`Есть возможность задать одно из состояний (`}<inlineCode parentName="p">{`disabled`}</inlineCode>{`, `}<inlineCode parentName="p">{`hover`}</inlineCode>{`, `}<inlineCode parentName="p">{`focused`}</inlineCode>{`), передав компоненту одноименный проп. Это может быть полезно при использовании `}<inlineCode parentName="p">{`BadgeLabel`}</inlineCode>{` в качестве составной части другого компонента.`}</p>
    <Polymorphic component="BadgeLabel" mdxType="Polymorphic" />
    <Playground __position={2} __code={'<div style={{ display: \'flex\' }}>\n  <BadgeLabel size=\"small\" variant=\"filled\">\n    22\n  </BadgeLabel>\n  <Space size=\"small_3x\" horizontal />\n  <BadgeLabel focused size=\"small\" variant=\"filled\">\n    label\n  </BadgeLabel>\n  <Space size=\"small_3x\" horizontal />\n  <BadgeLabel clickable>Clickable</BadgeLabel>\n</div>'} __scope={{
      props,
      DefaultLayout,
      BadgeLabel,
      Space,
      Playground,
      Props,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div style={{
        display: "flex"
      }}>
    <BadgeLabel size="small" variant="filled" mdxType="BadgeLabel">
      22
    </BadgeLabel>
    <Space size="small_3x" horizontal mdxType="Space" />
    <BadgeLabel focused size="small" variant="filled" mdxType="BadgeLabel">
      label
    </BadgeLabel>
    <Space size="small_3x" horizontal mdxType="Space" />
    <BadgeLabel clickable mdxType="BadgeLabel">
      Clickable
    </BadgeLabel>
  </div>
    </Playground>
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "состояния"
    }}>{`Состояния`}</h3>
    <p>{`Компоненту возможно задать одно из состояний (`}<inlineCode parentName="p">{`disabled`}</inlineCode>{`, `}<inlineCode parentName="p">{`hover`}</inlineCode>{`, `}<inlineCode parentName="p">{`focused`}</inlineCode>{`), передав ему одноименный проп. Это может быть полезно при использовании HseBadgeLabel в качестве составной части другого компонента`}</p>
    <Playground __position={3} __code={'<div>\n  <BadgeLabel disabled size=\"small\" variant=\"filled\">\n    Disabled\n  </BadgeLabel>\n  <Space size=\"small_3x\" horizontal />\n  <BadgeLabel size=\"small\" variant=\"filled\" hover>\n    Hover\n  </BadgeLabel>\n  <Space size=\"small_3x\" horizontal />\n  <BadgeLabel size=\"small\" variant=\"filled\" focused>\n    Focused\n  </BadgeLabel>\n</div>'} __scope={{
      props,
      DefaultLayout,
      BadgeLabel,
      Space,
      Playground,
      Props,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div>
    <BadgeLabel disabled size="small" variant="filled" mdxType="BadgeLabel">
      Disabled
    </BadgeLabel>
    <Space size="small_3x" horizontal mdxType="Space" />
    <BadgeLabel size="small" variant="filled" hover mdxType="BadgeLabel">
      Hover
    </BadgeLabel>
    <Space size="small_3x" horizontal mdxType="Space" />
    <BadgeLabel size="small" variant="filled" focused mdxType="BadgeLabel">
      Focused
    </BadgeLabel>
  </div>
    </Playground>
    <h3 {...{
      "id": "размеры"
    }}>{`Размеры`}</h3>
    <p>{`У лейбла три размера: `}<inlineCode parentName="p">{`smallX`}</inlineCode>{`, `}<inlineCode parentName="p">{`small`}</inlineCode>{`, `}<inlineCode parentName="p">{`medium`}</inlineCode>{`. Передайте в проп `}<inlineCode parentName="p">{`size`}</inlineCode>{` нужный`}</p>
    <Playground __position={4} __code={'<div style={{ display: \'flex\', alignItems: \'center\' }}>\n  <BadgeLabel\n    size={BadgeLabel.Size.smallX}\n    variant={BadgeLabel.Variant.outlined}\n    hover\n  >\n    8/10\n  </BadgeLabel>\n  <Space size={Space.Size.small_3x} horizontal />\n  <BadgeLabel size={BadgeLabel.Size.small} variant=\"filled\" hover>\n    Small\n  </BadgeLabel>\n  <Space size={Space.Size.small_3x} horizontal />\n  <BadgeLabel size={BadgeLabel.Size.medium} hover>\n    Medium\n  </BadgeLabel>\n</div>'} __scope={{
      props,
      DefaultLayout,
      BadgeLabel,
      Space,
      Playground,
      Props,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div style={{
        display: 'flex',
        alignItems: 'center'
      }}>
    <BadgeLabel size={BadgeLabel.Size.smallX} variant={BadgeLabel.Variant.outlined} hover mdxType="BadgeLabel">
      8/10
    </BadgeLabel>
    <Space size={Space.Size.small_3x} horizontal mdxType="Space" />
    <BadgeLabel size={BadgeLabel.Size.small} variant="filled" hover mdxType="BadgeLabel">
      Small
    </BadgeLabel>
    <Space size={Space.Size.small_3x} horizontal mdxType="Space" />
    <BadgeLabel size={BadgeLabel.Size.medium} hover mdxType="BadgeLabel">
      Medium
    </BadgeLabel>
  </div>
    </Playground>
    <h3 {...{
      "id": "варианты-оформления"
    }}>{`Варианты оформления`}</h3>
    <p>{`BadgeLabel имеет два варианта оформления: `}<inlineCode parentName="p">{`outlined`}</inlineCode>{` и `}<inlineCode parentName="p">{`filled`}</inlineCode></p>
    <Playground __position={5} __code={'<div>\n  <BadgeLabel variant=\"outlined\" size=\"small\" hover>\n    Outlined\n  </BadgeLabel>\n  <Space size=\"small_3x\" horizontal />\n  <BadgeLabel size=\"small\" variant=\"filled\" hover>\n    Filled\n  </BadgeLabel>\n</div>'} __scope={{
      props,
      DefaultLayout,
      BadgeLabel,
      Space,
      Playground,
      Props,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div>
    <BadgeLabel variant="outlined" size="small" hover mdxType="BadgeLabel">
      Outlined
    </BadgeLabel>
    <Space size="small_3x" horizontal mdxType="Space" />
    <BadgeLabel size="small" variant="filled" hover mdxType="BadgeLabel">
      Filled
    </BadgeLabel>
  </div>
    </Playground>
    <h3 {...{
      "id": "кликабельный-вариант"
    }}>{`Кликабельный вариант`}</h3>
    <p>{`По умолчанию BadgeLabel некликабельный.
Передайте свойство `}<inlineCode parentName="p">{`clickable`}</inlineCode>{` для добавления возможности кликать.
Не используйте проп `}<inlineCode parentName="p">{`clickable`}</inlineCode>{`, если компонент является составной частью другого кликабельного компонента.
Сделайте кликабельным весь внешний компонент.`}</p>
    <p>{`Вы также можете задать проп `}<inlineCode parentName="p">{`component`}</inlineCode>{`, чтобы определить тип базового компонента (например, `}<inlineCode parentName="p">{`a`}</inlineCode>{` или `}<inlineCode parentName="p">{`button`}</inlineCode>{`).
Чтобы задать реакцию на клик, обработайте реакцию на событие `}<inlineCode parentName="p">{`onClick`}</inlineCode>{`, либо передайте дополнительные
пропы в соответствии с базовым компонентом (например, `}<inlineCode parentName="p">{`href`}</inlineCode>{` для `}<inlineCode parentName="p">{`a`}</inlineCode>{`).`}</p>
    <Playground __position={6} __code={'<BadgeLabel clickable component=\"button\">\n  Clickable BadgeLabel\n</BadgeLabel>'} __scope={{
      props,
      DefaultLayout,
      BadgeLabel,
      Space,
      Playground,
      Props,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <BadgeLabel clickable component="button" mdxType="BadgeLabel">
        Clickable BadgeLabel
    </BadgeLabel>
    </Playground>
    <h2 {...{
      "id": "пропы"
    }}>{`Пропы`}</h2>
    <Props of={BadgeLabel} mdxType="Props" />
    <p>{`Все остальные пропы пробрасываются на корневой элемент, задаваемый пропом `}<inlineCode parentName="p">{`component`}</inlineCode>{` (по умолчанию `}<inlineCode parentName="p">{`div`}</inlineCode>{`).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      